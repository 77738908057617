.Logo {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 17rem;
  width: 17rem;
  align-items: center;
  justify-content: center;
}
