.Nav {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.Nav--Container {
  display: flex;
  align-items: center;
  height: 10rem;
}

.Nav .Logo {
  margin-right: 3rem;
}

.Nav--Container .Nav--Sandwich {
  display: none;
}

.Nav--Container .Nav--Sandwich div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
}

.Nav--Drawer {
  display: flex;
  justify-content: center;
}

.Nav--Drawer .Link {
  margin: 2rem auto;
}

.Nav--Drawer .NavLink {
  margin: 0.5rem 4rem;
  text-align: center;
}

.Nav--Desktop {
  display: flex;
}

.Nav .Link {
  margin-right: 3rem;
}

@media screen and (max-width: 700px) {
  .Nav--Container .Nav--Sandwich {
    display: block;
    cursor: pointer;
  }

  .Nav--Desktop {
    display: none;
  }

  .Nav .Link {
    margin: 0 auto;
  }
}

