.Footer {
  padding: 3rem 0;
  background: #83BB50;
}
.float{
	position:fixed;
	width:80px;
	height:80px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:60px;
	text-align:center;
    font-size:50px;
	
  z-index:100;
}

.my-float{
	margin-top:16px;
}