
    section{
        width: 940px;   
        min-height: 400px;
        margin: 0 auto;
        padding: 10px 0 0 0;    
        }
        
        .imgbox{
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
        }
        
        .img{
            height: 45px;
            width: 350px;
            background-color: #83BB50;
        }
        
        .text{
            position: relative;
            bottom: -10px; /*height of your image*/
            left: 15px;
            color: white;

        }


