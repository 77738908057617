.Benefits {
}

.Benefit {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 40px;
}

@media screen and (min-width: 700px) and (max-width: 1149px) {
  .Benefit {
    width: 50%;
  }
}

@media screen and (min-width: 1150px) {
  .Benefit {
    width: 33%;
  }
}

.Benefit .LazyImage {
  max-width: 178px;
  max-height: 252px;
}
