.About {
}
h2 {
    display: block;
    font-size: 1.65em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    color: #83BB50;
  }
  h3 {
    display: block;
    font-size: 1.25em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }