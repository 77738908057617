section{
    width: 940px;   
    min-height: 400px;
    margin: 0 auto;
    padding: 10px 0 0 0;    
    }
    
    .imgbox{
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 30px;
    }
    
    .img{
        height: 200px;
        width: 200px;
        background-color:  #83BB50;
    }
    
    .text{
        position: relative;
        bottom: -250px; /*height of your image*/
        left: 12px;
        color: white;

    }